/*!
 * Bootstrap Reboot v4.1.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: 0.5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }

a:hover {
  color: #0056b3;
  text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):focus {
  outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

nb {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

/*# sourceMappingURL=bootstrap-reboot.css.map */
@font-face {
  font-family: "OpenSans";
  font-style: normal;
  font-weight: 300;
  src: url("../assets/fonts/OpenSans/OpenSans-Light.ttf") format("truetype");
  text-rendering: optimizeLegibility; }

@font-face {
  font-family: "OpenSans";
  font-style: normal;
  font-weight: normal;
  src: url("../assets/fonts/OpenSans/OpenSans-Regular.ttf") format("truetype");
  text-rendering: optimizeLegibility; }

@font-face {
  font-family: "OpenSans";
  font-style: normal;
  font-weight: 600;
  src: url("../assets/fonts/OpenSans/OpenSans-SemiBold.ttf") format("truetype");
  text-rendering: optimizeLegibility; }

@font-face {
  font-family: "OpenSans";
  font-style: normal;
  font-weight: bold;
  src: url("../assets/fonts/OpenSans/OpenSans-Bold.ttf") format("truetype");
  text-rendering: optimizeLegibility; }

@font-face {
  font-family: "OpenSans";
  font-style: normal;
  font-weight: 800;
  src: url("../assets/fonts/OpenSans/OpenSans-ExtraBold.ttf") format("truetype");
  text-rendering: optimizeLegibility; }

.d-none {
  display: none; }

html {
  font-size: 62.5%; }

body {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "OpenSans", sans-serif;
  font-size: 1.6rem; }

.home {
  background: #eef0f2;
  transition: background 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275); }
  .home__container {
    display: flex;
    padding: 3rem 5rem;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh; }
    @media (max-width: 575px) {
      .home__container {
        padding: 3rem; } }
  .home.dark {
    background: #20252d; }
    .home.dark .dark-white {
      color: #fff !important; }
    .home.dark .board {
      background: rgba(255, 255, 255, 0.2) !important; }
    .home.dark .dark-grey {
      color: rgba(255, 255, 255, 0.8) !important; }
  .home .container {
    display: flex;
    width: 100%; }
    @media (min-width: 992px) and (max-width: 1199px) {
      .home .container {
        max-width: 1140px;
        margin: 0 auto; } }
    @media (min-width: 1200px) {
      .home .container {
        max-width: 1400px;
        margin: 0 auto; } }
  .home .row {
    display: flex;
    flex-direction: row; }
    @media (max-width: 991px) {
      .home .row {
        flex-direction: column; } }
    .home .row .col {
      flex: 1;
      padding: 0 2rem; }
      .home .row .col-5 {
        width: calc((100% / 12) * 5); }
        @media (max-width: 991px) {
          .home .row .col-5 {
            width: 100%;
            padding: 0; } }
      .home .row .col-7 {
        width: calc((100% / 12) * 7); }
        @media (max-width: 991px) {
          .home .row .col-7 {
            width: 100%;
            padding: 0; } }
      @media (max-width: 991px) {
        .home .row .col {
          flex: 1;
          padding: 0; } }
  .home .content {
    flex: 1;
    padding-top: 10rem; }
    .home .content__title {
      color: #20252d;
      letter-spacing: 1px; }
      .home .content__title span {
        color: #f15d2a; }
    @media (max-width: 575px) {
      .home .content {
        padding-top: 5rem; } }
    .home .content__subtitle {
      color: #4a4a4a;
      font-size: 1.4rem;
      letter-spacing: 1px;
      margin: 0;
      transition: 0.1s color;
      width: 30rem; }
      @media (max-width: 575px) {
        .home .content__subtitle {
          width: 100%; } }
    .home .content .options {
      margin: 5rem 0; }
      .home .content .options__title {
        color: #4a4a4a;
        font-size: 1.4rem;
        letter-spacing: 1px;
        margin: 0;
        transition: 0.1s color;
        color: #f15d2a;
        font-weight: bold;
        margin-bottom: 2rem; }
      .home .content .options-select {
        width: 80%;
        margin-bottom: 2rem; }
        @media (max-width: 991px) {
          .home .content .options-select {
            width: 100%; } }
        .home .content .options-select__value-container {
          padding-left: 1.5rem; }
        .home .content .options-select__control {
          height: 5rem;
          border: none;
          box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.1); }
        .home .content .options-select__indicator-separator {
          display: none; }
        .home .content .options-select__dropdown-indicator {
          padding-right: 1.5rem; }
        .home .content .options-select__single-value {
          color: #4a4a4a;
          font-size: 1.4rem;
          letter-spacing: 1px;
          margin: 0;
          transition: 0.1s color;
          font-weight: bold; }
        .home .content .options-select__menu {
          margin: 0;
          padding: 0 !important;
          top: 90%; }
          .home .content .options-select__menu-list {
            padding: 0 !important; }
    @media (max-width: 991px) {
      .home .content .boards {
        margin-bottom: 15rem; } }
    .home .content .board {
      background: #20252d;
      border-radius: 1rem;
      border-left: 1rem solid #f15d2a;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 3rem;
      transition: 0.3s background cubic-bezier(0.175, 0.885, 0.32, 1.275); }
      .home .content .board .faded {
        opacity: 0.5; }
      .home .content .board pre {
        margin: 0; }
      .home .content .board__title {
        color: #20252d;
        letter-spacing: 1px;
        margin-bottom: 1rem; }
      .home .content .board--2 .Typist {
        font-weight: normal; }
      .home .content .board__group--2 {
        margin-top: 5rem; }
      @media (max-width: 575px) {
        .home .content .board {
          padding: 2rem; } }
      .home .content .board * {
        color: #fff;
        letter-spacing: 1px;
        font-weight: 300; }

.Typist {
  white-space: pre-wrap; }

.copy {
  position: relative;
  display: inline-block; }
  .copy__image {
    cursor: pointer;
    position: relative;
    width: 2rem; }
  .copy__popover {
    background: #fff;
    border-radius: 0.3rem;
    bottom: 50%;
    color: #20252d !important;
    display: inline-block;
    font-size: 1rem;
    padding: 0.2rem 0.5rem;
    position: absolute;
    opacity: 0;
    right: 50%;
    text-align: center;
    text-transform: capitalize;
    transform: translateX(50%);
    transition: all 0.3s; }
    .copy__popover.show {
      opacity: 1;
      bottom: 150%; }
    .copy__popover::after {
      border: 5px transparent solid;
      border-top-color: #fff;
      content: '';
      display: block;
      left: 50%;
      position: absolute;
      transform: translateX(-50%); }

.copy_contents {
  position: relative;
  display: inline-block; }
.copy_contents_image {
  cursor: pointer;
  position: relative;
  width: 2rem; }
.copy_contents_popover {
  background: #fff;
  border-radius: 0.3rem;
  bottom: 50%;
  color: #20252d !important;
  display: inline-block;
  font-size: 1rem;
  padding: 0.2rem 0.5rem;
  position: absolute;
  opacity: 0;
  right: 50%;
  text-align: center;
  text-transform: capitalize;
  transform: translateX(50%);
  transition: all 0.3s; }
.copy_contents_popover.show {
  opacity: 1;
  bottom: 100%; }
.copy_contents_popover::after {
  border: 5px transparent solid;
  border-top-color: #fff;
  content: '';
  display: block;
  left: 50%;
  position: absolute;
  transform: translateX(-50%); }

.toggle {
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .toggle__option {
    color: #4a4a4a;
    font-size: 1.4rem;
    letter-spacing: 1px;
    margin: 0;
    transition: 0.1s color;
    transition: all 0.3s; }
    .toggle__option--left {
      margin-right: 1rem; }
    .toggle__option--right {
      margin-left: 1rem; }
  @media (max-width: 575px) {
    .toggle {
      margin-bottom: 2rem; } }

.toggler {
  display: inline-block;
  margin: 7px;
  margin: 2.5px;
  width: 45px;
  height: 10px;
  text-align: center;
  position: relative; }
  .toggler input[type='checkbox'] {
    display: none; }
    .toggler input[type='checkbox']:checked ~ .button {
      background: #fff;
      left: 25px;
      transition: 0.5s; }
    .toggler input[type='checkbox']:checked ~ .switch {
      background: #f15925;
      transition: 0.5s; }
  .toggler .switch {
    display: block;
    width: 45px;
    height: 10px;
    background: #f15d2a;
    border-radius: 5px;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.5s;
    cursor: pointer; }
  .toggler .button {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: white;
    position: absolute;
    top: -5px;
    left: 0;
    box-shadow: 0 0.9px 1.8px rgba(0, 0, 0, 0.4);
    transition: 0.5s;
    cursor: pointer; }

.nav {
  align-items: center;
  display: flex;
  justify-content: space-between; }
  @media (max-width: 575px) {
    .nav {
      align-items: center;
      flex-direction: column;
      justify-content: center; } }
  .nav .language {
    align-items: center;
    display: flex;
    margin-right: 4rem; }
    @media (max-width: 575px) {
      .nav .language {
        margin-right: 0;
        margin-bottom: 2rem; } }
    .nav .language__title {
      color: #4a4a4a;
      font-size: 1.4rem;
      letter-spacing: 1px;
      margin: 0;
      transition: 0.1s color; }
    .nav .language__select {
      width: 15rem;
      margin-left: 3rem; }
      .nav .language__select__control {
        border: none;
        box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.1); }
      .nav .language__select__indicator-separator {
        display: none; }
      .nav .language__select__single-value {
        color: #4a4a4a;
        font-size: 1.4rem;
        letter-spacing: 1px;
        margin: 0;
        transition: 0.1s color; }
      .nav .language__select__menu {
        margin: 0;
        padding: 0 !important; }
        .nav .language__select__menu-list {
          padding: 0 !important; }

.footer {
  margin-top: 2rem; }
  .footer__copyright {
    display: block;
    color: #4a4a4a;
    font-size: 1.4rem;
    letter-spacing: 1px;
    margin: 0;
    transition: 0.1s color;
    text-align: center;
    line-height: 4rem; }
    .footer__copyright span {
      color: crimson; }
    .footer__copyright:hover {
      color: initial; }
    .footer__copyright a {
      color: inherit; }
      .footer__copyright a:hover {
        text-decoration: underline; }
  .footer .logo {
    float: right; }
    @media (max-width: 768px) {
      .footer .logo {
        justify-content: center;
        margin-bottom: 1rem; } }
    .footer .logo--github {
      width: 4rem;
      height: 4rem;
      fill: blue; }
  .footer__link {
    margin-left: 2rem;
    position: relative; }
    .footer__link::before {
      content: '';
      display: inline-block;
      width: 3px;
      height: 3px;
      border-radius: 100%;
      background-color: white;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -1.2rem; }
